import { connect, useDispatch, useSelector } from 'react-redux';
import { Header } from '../commons/pageHeaderComponent';
import { AppState } from '../redux/reducers';
import * as MedicalAppointmentStore from '../redux/reducers/medicalappointmentsreducer';
import { Label, Separator, Text } from '@fluentui/react';

import FeedBackHeaderImage from '../images/feedbackimage.svg';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { FeedbackFormComponent } from '../components/Feedbackform/FeedbackFormComponent';

export const FeedbackPage = () => {
  const CurrentMedicalAppointment = useSelector(
    (state: AppState) => state.MedicalAppointment
  );

  const dispatch = useDispatch();
  const { appointmentCallId } = useParams();

  useEffect(() => {
    dispatch(mapDispatchToProps.RequestMedicalAppointment(appointmentCallId));
  }, [dispatch, appointmentCallId]);

  return (
    <>
      <Header
        UserId={CurrentMedicalAppointment?.MedicalAppointment?.patient!.nationalIdentityCardNumber.toString()}
        UserName={
          CurrentMedicalAppointment?.MedicalAppointment?.patient.name +
          ' ' +
          CurrentMedicalAppointment?.MedicalAppointment?.patient.surName
        }
        Title="Atención Telemedicina"
      />
      <div className="FeedbackHeader">
        <img src={FeedBackHeaderImage} alt="" />
        <div>
          <h1>Gracias por usar el servicio de Telemedicina</h1>
          <Separator />
        </div>
      </div>
      <div className="FeedbackForm">
        <Label>¿cómo calificarias los siguientes aspectos del servicio?</Label>
        <FeedbackFormComponent AppoinmentCallId={appointmentCallId} />
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalAppointment
});

const mapDispatchToProps = {
  ...MedicalAppointmentStore.actionCreators
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedbackPage as any);
