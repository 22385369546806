import {
  ActivityItem,
  Icon,
  Link,
  mergeStyleSets,
  Text
} from '@fluentui/react';

import './RecommendationsComponent.css';

const classNames = mergeStyleSets({
  RecommendationItem: {
    marginTop: '0px'
  },
  nameText: {
    fontWeight: 'normal'
  },
  TitleMargin: {
    marginBottom: '1em'
  }
});

/* eslint-disable react/jsx-no-bind */
const RecommendationItems = [
  {
    key: 1,
    activityDescription: [
      <Text key={1} className={classNames.nameText}>
        Por favor verificá que el volumen de tu dispositivo esté activo.
      </Text>
    ],
    activityIcon: <Icon iconName={'MegaphoneSolid'} />
  },
  {
    key: 2,
    activityDescription: [
      <Text key={1} className={classNames.nameText}>
        Tené la cámara encendida en todo momento
      </Text>
    ],
    activityIcon: <Icon iconName={'MegaphoneSolid'} />
  }
];

export const RecommendationsComponent = () => {
  return (
    <div className="RecommendationsBox">
      <label className={classNames.TitleMargin}>
        Tenga en cuenta las siguientes recomendaciones
      </label>
      {RecommendationItems.map((item: { key: string | number }) => (
        <ActivityItem
          {...item}
          key={item.key}
          className={classNames.RecommendationItem}
        />
      ))}
    </div>
  );
};
