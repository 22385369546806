import React from 'react';
import { UserInfo } from './userinfoComponent';
import LogoIcthys from '../images/icthysLogo.png';
import LogoCinme from '../images/Logocinme.png';

interface Props {
  Title: string;
  UserName: string | undefined;
  UserId: string | undefined;
}

export const Header = (props: Props) => (
  <header>
    <img className="LogoCinme" src={LogoCinme} alt="Cinme S.A." />
    <label className="no_show_mobile">{props.Title}</label>
    {/* <UserInfo UserId={props.UserId} UserName={props.UserName} /> */}
  </header>
);
