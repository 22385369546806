import { useEffect, useRef, useState } from 'react';
import { ImageFit } from '@fluentui/react/lib/Image';
import { TestImages } from '@fluentui/example-data';
import './WaitingVideoComponent.css';
import { VideoControlComponent } from '../VideoControl/VideoControlComponent';
import { AppointmentWidgetComponent } from '../appointment/AppointmentWigetComponent';
import {
  FontIcon,
  mergeStyles,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Text
} from '@fluentui/react';
import { User } from '../../models/UserModel';
import { MedicalAppointment } from '../../models/MedicalAppointmentModel';

export interface IProps {
  stream: MediaStream | undefined;
  initialState: boolean;
  PermitStartTheCall: boolean;
  areCalling: boolean;
  CurrentUser: User;
  GuestUser: User | undefined;
  Appointment: MedicalAppointment;
  LocalCameraError: boolean;
  onStartCall: () => void;
  onEndAppointment: () => void;
  onAnswerCall: () => void;
}

export const WaitingVideoComponent = (props: IProps) => {
  const videoStream = useRef<HTMLVideoElement>(null);
  const [CameraON, setCameraON] = useState<boolean>(props.initialState);
  const [MicrophoneON, setMicrophoneON] = useState<boolean>(props.initialState);

  useEffect(() => {
    if (props.stream) {
      props.stream.getVideoTracks()[0].enabled = props.initialState;
      if (videoStream.current) videoStream.current.srcObject = props.stream;
    }
  }, [props.initialState, props.stream, props.GuestUser]);

  const handleMicrophoneChangeState = (microphoneCurrentSate: boolean) => {
    if (props.stream) {
      setMicrophoneON(microphoneCurrentSate);
      props.stream.getAudioTracks()[0].enabled = microphoneCurrentSate;
    }
  };
  const handleCameraChangeState = (cameraCurrentSate: boolean) => {
    if (props.stream) {
      setCameraON(cameraCurrentSate);
      props.stream.getVideoTracks()[0].enabled = cameraCurrentSate;
    }
  };

  const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    top: 40,
    left: 100,
    position: 'absolute',
    margin: '0 10px'
  });

  const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
    greenYellow: [{ color: 'greenyellow' }, iconClass],
    salmon: [{ color: 'Red' }, iconClass]
  });

  return (
    <div className="StreamWidget">
      <video
        className="VideoStream"
        playsInline
        muted
        ref={videoStream}
        autoPlay
      />
      {props.LocalCameraError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel="Close"
        >
          La camara esta siendo utilizada por otra aplicación cierre esta
          aplicación y recargue la página
        </MessageBar>
      )}

      {!CameraON && (
        <span>
          <FontIcon
            iconName="VideoOff"
            className={mergeStyles(
              iconClass,
              {
                width: 50,
                height: 50
              },
              classNames.salmon
            )}
          />
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
          >
            No olvide activar la cámara
          </MessageBar>
        </span>
      )}
      <VideoControlComponent
        initialState={props.initialState}
        PermitStartTheCall={props.PermitStartTheCall}
        onCameraStateChange={handleCameraChangeState}
        onMicrophoneStateChange={handleMicrophoneChangeState}
        onStartCall={props.onStartCall}
        areCalling={props.areCalling}
        GuestUser={props.GuestUser}
        LocalCameraError={props.LocalCameraError}
        onEndAppointment={props.onEndAppointment}
        onAnswerCall={props.onAnswerCall}
      />
      <AppointmentWidgetComponent
        appointment={props.Appointment}
        CurrentUser={props.CurrentUser}
        GuestUser={props.GuestUser}
      />
    </div>
  );
};
