import { TestImages } from '@fluentui/example-data';
import Moment from 'react-moment';
import DoctorIcon from '../../images/doctorFake.jpg';
import {
  IPersonaSharedProps,
  Label,
  Persona,
  PersonaPresence,
  PersonaSize,
  Stack,
  Text,
  IStackTokens,
  IIconProps,
  Icon,
  FontIcon,
  mergeStyles,
  Separator
} from '@fluentui/react';
import './AppointmentWigetComponent.css';

import { useState } from 'react';
import { MedicalAppointment } from '../../models/MedicalAppointmentModel';
import { User } from '../../models/UserModel';

export interface IProps {
  appointment: MedicalAppointment;
  CurrentUser: User;
  GuestUser: User | undefined;
}

export const AppointmentWidgetComponent = (props: IProps) => {
  const [renderDetails, updateRenderDetails] = useState(true);
  const stackProps: IStackTokens = { padding: 25 };

  const DateTimeIcon: IIconProps = { iconName: 'DateTime' };

  const Doctor: IPersonaSharedProps = {
    // imageUrl: DoctorIcon,
    imageInitials:
      props.appointment?.doctor.surName &&
      ', ' &&
      props.appointment?.doctor.name
        .split(' ')
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join(''),
    text:
      props.appointment?.doctor.surName &&
      ', ' &&
      props.appointment?.doctor.name,
    secondaryText: props.appointment?.doctor.medicalLicense
    // tertiaryText: 'In a meeting',
    // optionalText: 'Available at 4:00pm'
  };

  const Patient: IPersonaSharedProps = {
    // imageUrl: DoctorIcon,
    imageInitials:
      props.appointment?.patient.surName &&
      ', ' &&
      props.appointment?.patient.name
        .split(' ')
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join(''),
    text:
      props.appointment?.patient.surName &&
      ', ' &&
      props.appointment?.patient.name,
    secondaryText: props.appointment?.patient.email
    // tertiaryText: 'In a meeting',
    // optionalText: 'Available at 4:00pm'
  };

  const iconClass = mergeStyles({
    fontSize: 20,
    height: 15,
    width: 15,
    margin: '0 10px'
  });

  return (
    <>
      <Stack tokens={stackProps}>
        <Separator />
        <Label>Turno</Label>
        <Text className="labelFecha">
          <FontIcon
            iconName="DateTime"
            className={mergeStyles(iconClass, {
              width: 15,
              height: 15
            })}
          />
          <Moment format="dddd Do MMMM, h:mm a">
            {props.appointment?.appointmentDate}
          </Moment>
        </Text>
        <Label>
          {props.CurrentUser?.userType === 'patient'
            ? props.appointment?.specialtyDescription
            : 'Paciente'}
        </Label>
        {props.CurrentUser?.userType === 'doctor' ? (
          <Persona
            {...Patient}
            size={PersonaSize.size48}
            presence={
              props.GuestUser ? PersonaPresence.online : PersonaPresence.offline
            }
            hidePersonaDetails={!renderDetails}
            imageAlt="Annie Lindqvist, status is away"
          />
        ) : (
          <Persona
            {...Doctor}
            size={PersonaSize.size48}
            presence={
              props.GuestUser ? PersonaPresence.online : PersonaPresence.offline
            }
            hidePersonaDetails={!renderDetails}
            imageAlt="Annie Lindqvist, status is away"
          />
        )}
      </Stack>
    </>
  );
};
