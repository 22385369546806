import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import NumberFormat from 'react-number-format';

export interface Props {
  Status: string;
  Alive: boolean;
}

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}

export const CallStatusComponent = (props: Props) => {
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (props.Alive) {
      const timeout = setTimeout(() => {
        setTime(time + 1);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props.Alive, time]);

  const secondsToTime = (secs: number): Time => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj: Time = {
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
    return obj;
  };

  const PadLeadingZeros = (num: number, size: number): string => {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  };

  return (
    <div className="CallStatusWidget">
      <div
        className={props.Alive ? 'CallStatusLight red' : 'CallStatusLight'}
      ></div>
      <label>
        <strong className={props.Alive ? 'border' : ''}>{props.Status}</strong>
        <span>
          {props.Alive
            ? PadLeadingZeros(secondsToTime(time).hours, 2) +
              ':' +
              PadLeadingZeros(secondsToTime(time).minutes, 2) +
              ':' +
              PadLeadingZeros(secondsToTime(time).seconds, 2)
            : null}
        </span>
      </label>
    </div>
  );
};
