import { io } from 'socket.io-client';

export const socket = io('https://signal.infra.idbnar.com/');
//export const socket = io('http://localhost:8001/');

socket.on('connect', () => {
  console.log(socket.id);
  console.log('se conecta al socket');
});

socket.on('connection_error', (err: any) => {
  console.log(err.req); // the request object
  console.log(err.code); // the error code, for example 1
  console.log(err.message); // the error message, for example "Session ID unknown"
  console.log(err.context); // some additional error context
});
