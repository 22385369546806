import { useSelector } from 'react-redux';
import '../App.css';
import { Header } from '../commons/pageHeaderComponent';
import { RecommendationsComponent } from '../components/Recommendations/RecommendationsComponent';
import { WaitingVideoComponent } from '../components/WaitingVideo/WaitingVideoComponent';
import PageLogo from '../images/waitroomdoctoricon.svg';
import { MedicalAppointment } from '../models/MedicalAppointmentModel';
import { User } from '../models/UserModel';
import { AppState } from '../redux/reducers';

export interface IProps {
  localStream: MediaStream | undefined;
  PatientIsConnected: boolean;
  GuestUser: User | undefined;
  CurrentUser: User;
  Appointment: MedicalAppointment;
  areCalling: boolean;
  LocalCameraError: boolean;
  onStartCalling: () => void;
  onAnswerCall: () => void;
  onEndAppointment: () => void;
}

export const WaitRoomDoctor = (props: IProps) => {
  const CurrentMedicalAppointment = useSelector(
    (state: AppState) => state.MedicalAppointment
  );

  return (
    <div className="App">
      <Header
        UserId={
          CurrentMedicalAppointment?.MedicalAppointment?.doctor!.medicalLicense
        }
        UserName={
          CurrentMedicalAppointment?.MedicalAppointment?.doctor.name +
          ' ' +
          CurrentMedicalAppointment?.MedicalAppointment?.doctor.surName
        }
        Title="Atención Médica - Teleconsulta"
      />
      <div className="Container">
        <div>
          <div>
            <div></div>
            <div className="PageContainer">
              <div className="PageContainerDoctor">
                <div className="PrincipalColumn">
                  <div>
                    <img className="PageDoctorIcon" src={PageLogo} alt="" />
                    {props.PatientIsConnected ? (
                      <h1 className="Title">El afiliado lo está esperando</h1>
                    ) : (
                      <h1 className="Title">
                        Esperando a que se conecte el paciente
                      </h1>
                    )}
                    <label className="Subtitle">
                      Cuando esté listo presione el botón para iniciar la
                      videollamada
                    </label>
                  </div>
                  <RecommendationsComponent />
                </div>
                <div>
                  <WaitingVideoComponent
                    stream={props.localStream}
                    initialState={true}
                    PermitStartTheCall={true}
                    onStartCall={props.onStartCalling}
                    areCalling={props.areCalling}
                    CurrentUser={props.CurrentUser}
                    GuestUser={props.GuestUser}
                    Appointment={props.Appointment}
                    LocalCameraError={props.LocalCameraError}
                    onEndAppointment={props.onEndAppointment}
                    onAnswerCall={props.onAnswerCall}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitRoomDoctor;
