import { Label, mergeStyles, Separator, Text } from '@fluentui/react';
import logoIchtys from '../images/icthysLogo.png';
import logoCinme from '../images/Logocinme.png';

export const EndCallPage = () => {
  const titleClass = mergeStyles({
    fontSize: 30
  });

  const separatorClass = mergeStyles({
    width: 300
  });

  return (
    <div className="textCenter">
      <img src={logoCinme} alt="" />
      <Separator className={separatorClass} />
      <Label className={titleClass}>LLamada finalizada</Label>
      <Text>ya puede cerrar esta ventana</Text>
    </div>
  );
};
