import React, { useEffect, useState, useRef, Suspense } from 'react';

// import io from 'socket.io-client';
import Peer, { SignalData } from 'simple-peer';
import { Howl } from 'howler';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

//components
import { WaitRoomPatient } from './WaitRoomPatient';
import { WaitRoomDoctor } from './WaitRoomDoctor';
import { CallRoom } from './CallRoom';

import ringtone from '../sounds/ringtone.mp3';
import callRingTone from '../sounds/phone-calling.mp3';
import { AppState } from '../redux/reducers';
import * as MedicalAppointmentStore from '../redux/reducers/medicalappointmentsreducer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { User } from '../models/UserModel';
import logoIchtys from '../images/icthysLogo.png';
import logoCinme from '../images/Logocinme.png';
//import { withRouter } from 'react-router-dom';

import * as socketManager from '../commons/socket';
import { Label, mergeStyles, Separator, Spinner, Text } from '@fluentui/react';

const ringtoneSound = new Howl({
  src: [ringtone],
  loop: true,
  preload: true
});

const callSound = new Howl({
  src: [callRingTone],
  loop: true,
  preload: true
});

type UserList = { [key: string]: string[] };

export const VideoCall = () => {
  const dispatch = useDispatch();
  const CurrentMedicalAppointment = useSelector(
    (state: AppState) => state.MedicalAppointment
  );

  const [state, setState] = useState({});

  let { callId, medicoId } = useParams();

  // const [yourID, setYourID] = useState<string>('');
  // const [receivingCall, setReceivingCall] = useState(false);
  const [GuestGone, setGuestGone] = useState<boolean>(false);
  const [errorCamera, setErrorCamera] = useState<boolean>(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const [areCalling, setareCalling] = useState(false);
  // const [play, setPlay] = useState(true);
  const [joined, setJoined] = useState(false);
  const [CurrentUser, setCurrentUser] = useState<User>();
  const [GuestUser, setGuestUser] = useState<User>();
  const [doctor, setDoctor] = useState<any>();
  const [patient, setPatient] = useState<any>();
  const [Users, setUsers] = useState<User[]>([]);
  const [RemoteStreamConnected, setRemoteStreamConnected] =
    useState<boolean>(false);
  const [GuestIsConnected, setGuestIsConnected] = useState<boolean>(false);
  const [InitializeSocket, setInitializeSocket] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      CurrentMedicalAppointment &&
      callId &&
      !CurrentMedicalAppointment.MedicalAppointment
    ) {
      dispatch(mapDispatchToProps.RequestMedicalAppointment(callId));
    }

    if (CurrentMedicalAppointment?.MedicalAppointment && !joined) {
      joinRoom();
    }

    if (!InitializeSocket) {
      setInitializeSocket(true);

      socketManager.socket.on('room_created', async (event: any) => {
        console.log('Socket event callback: room_created', event);
        await setLocalStream(mediaConstraints);
        setCurrentUser(event.user);
        setJoined(true);
        // isRoomCreator = true;
      });

      socketManager.socket.on('room_joined', async (event: any) => {
        console.log('Socket event callback: room_joined', event);
        await setLocalStream(mediaConstraints);
        isRoomCreator = event.user.userType === 'patient' ? true : false;
        setCurrentUser(event.user);

        var user = event.users.filter(
          (filterUser: User) => filterUser.userType !== event.user.userType
        );

        console.log('GuestUser', user);

        if (user.length > 0) {
          setGuestUser(user);
        }

        setJoined(true);
      });

      socketManager.socket.on('webrtc_user_joined', (event: any) => {
        console.log('Socket event callback: webrtc_user_joined', event);
        setGuestIsConnected(true);
        setGuestUser(event);
        isRoomCreator = event.userType === 'patient' ? false : true;
        console.log(GuestUser?.userName);
      });

      socketManager.socket.on('roomUsers', (event: any) => {
        setUsers(event.users);
      });

      socketManager.socket.on('full_room', async (event: any) => {
        console.log('Socket event callback: room_joined', event);
        await setLocalStream(mediaConstraints);
        isRoomCreator = event.user.userType === 'patient' ? true : false;
        setCurrentUser(event.user);

        var user = event.users.filter(
          (filterUser: User) => filterUser.userType !== event.user.userType
        );

        console.log('GuestUser', user);

        if (user.length > 0) {
          setGuestUser(user);
        }
        console.log('The room is full, please try another one');
      });

      socketManager.socket.on('webrtc_user_are_gone', (event: any) => {
        setGuestUser(undefined);
        setGuestGone(true);
      });

      socketManager.socket.on('start_call', async () => {
        setareCalling(true);
        console.log('isRoomCreator', isRoomCreator);
        if (isRoomCreator) {
          rtcPeerConnection.current = new RTCPeerConnection(iceServers);
          addLocalTracks(rtcPeerConnection.current);
          rtcPeerConnection.current.ontrack = setRemoteStream;
          rtcPeerConnection.current.onicecandidate = sendIceCandidate;
          rtcPeerConnection.current.oniceconnectionstatechange =
            handlePeerDisconnection;
          console.log('Socket event callback: start_call');
          ringtoneSound.play();
          console.log('callAccepted', callAccepted);
          if (callAccepted) {
            await createOffer(rtcPeerConnection.current);
          }
        }
      });

      socketManager.socket.on('webrtc_offer', async (event: any) => {
        console.log('Socket event callback: webrtc_offer');
        console.log('isRoomCreator', isRoomCreator);
        if (!isRoomCreator) {
          rtcPeerConnection.current = new RTCPeerConnection(iceServers);
          addLocalTracks(rtcPeerConnection.current);
          rtcPeerConnection.current.ontrack = setRemoteStream;
          rtcPeerConnection.current.onicecandidate = sendIceCandidate;
          rtcPeerConnection.current.setRemoteDescription(
            new RTCSessionDescription(event)
          );
          rtcPeerConnection.current.oniceconnectionstatechange =
            handlePeerDisconnection;
          await createAnswer(rtcPeerConnection.current);
        }
      });

      socketManager.socket.on('webrtc_answer', (event: any) => {
        console.log('Socket event callback: webrtc_answer');
        rtcPeerConnection.current.setRemoteDescription(
          new RTCSessionDescription(event)
        );
      });

      socketManager.socket.on('webrtc_user_are_gone', (event: any) => {
        console.log('Socket event callback: webrtc_user_are_gone', event);
        setRemoteStreamConnected(false);
        setGuestIsConnected(false);
        setGuestUser(undefined);
      });

      socketManager.socket.on('webrtc_ice_candidate', (event: any) => {
        console.log('Socket event callback: webrtc_ice_candidate', event);

        // ICE candidate configuration.
        var candidate = new RTCIceCandidate({
          sdpMLineIndex: event.label,
          candidate: event.candidate,
          sdpMid: event.sdpMid
        });
        rtcPeerConnection.current.addIceCandidate(candidate);
      });
    }
    return () => {
      setState({}); // This worked for me
    };
  }, [
    CurrentMedicalAppointment?.MedicalAppointment,
    joined,
    socketManager.socket
  ]);

  // Variables.
  const mediaConstraints = {
    audio: true,
    video: { width: 1280, height: 720 }
  };

  // https://signalserver2.idbnar.com/ http://localhost:8001
  // const socket = io.connect('https://signalserver2.idbnar.com/', {});

  const localStream = useRef<MediaStream>();
  const remoteStream = useRef<MediaStream>();

  let rtcPeerConnection = useRef<any>();

  let isRoomCreator: boolean = false;

  // Free public STUN servers provided by Google.
  const iceServers = {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
      { urls: 'stun:stun2.l.google.com:19302' },
      { urls: 'stun:stun3.l.google.com:19302' },
      { urls: 'stun:stun4.l.google.com:19302' },
      {
        urls: 'turn:numb.viagenie.ca',
        username: 'sultan1640@gmail.com',
        credential: '98376683'
      }
    ]
  };

  const handlePeerDisconnection = (event: any) => {
    if (event.currentTarget.iceConnectionState === 'disconnected') {
      setRemoteStreamConnected(false);
      setGuestIsConnected(false);
      setGuestUser(undefined);
    }
    if (event.currentTarget.iceConnectionState === 'connected') {
      setRemoteStreamConnected(true);
    }
  };

  const handleEndAppointment = () => {};

  // SOCKET EVENT CALLBACKS =====================================================

  // FUNCTIONS ==================================================================
  function joinRoom() {
    socketManager.socket.connect();
    setDoctor(CurrentMedicalAppointment?.MedicalAppointment?.doctor);
    setPatient(CurrentMedicalAppointment?.MedicalAppointment?.patient);
    if (!joined) {
      if (medicoId) {
        socketManager.socket.emit('join', {
          userName:
            CurrentMedicalAppointment?.MedicalAppointment?.doctor.surName &&
            CurrentMedicalAppointment.MedicalAppointment.doctor.name,
          userId: CurrentMedicalAppointment?.MedicalAppointment?.doctor.id,
          timeStampConnection: new Date(),
          userType: 'doctor',
          roomId: callId
        });
      } else {
        socketManager.socket.emit('join', {
          userName:
            CurrentMedicalAppointment?.MedicalAppointment?.patient.surName &&
            CurrentMedicalAppointment.MedicalAppointment.patient.name,
          userId: CurrentMedicalAppointment?.MedicalAppointment?.patient.id,
          timeStampConnection: new Date(),
          userType: 'patient',
          roomId: callId
        });
      }
    }
  }

  const setLocalStream = async (_mediaConstraints: any) => {
    let stream: MediaStream;
    try {
      stream = await navigator.mediaDevices.getUserMedia(_mediaConstraints);
      localStream.current = stream;
      rtcPeerConnection.current = new RTCPeerConnection(iceServers);
      console.log('peer', rtcPeerConnection);
      addLocalTracks(rtcPeerConnection.current);
      setErrorCamera(false);
    } catch (error) {
      setErrorCamera(true);
    }
  };

  const addLocalTracks = (_rtcPeerConnection: Peer.Instance) => {
    if (localStream.current) {
      localStream.current?.getTracks().forEach((track) => {
        _rtcPeerConnection.addTrack(track, localStream.current!);
      });
    }
  };

  const createOffer = async (_rtcPeerConnection: any) => {
    let sessionDescription;
    try {
      sessionDescription = await _rtcPeerConnection.createOffer();
      _rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error);
    }
    socketManager.socket.emit('webrtc_offer', {
      type: 'webrtc_offer',
      sdp: sessionDescription,
      roomId: callId
    });
  };

  const createAnswer = async (_rtcPeerConnection: any) => {
    let sessionDescription;
    try {
      sessionDescription = await _rtcPeerConnection.createAnswer();
      _rtcPeerConnection.setLocalDescription(sessionDescription);
    } catch (error) {
      console.error(error);
    }

    socketManager.socket.emit('webrtc_answer', {
      type: 'webrtc_answer',
      sdp: sessionDescription,
      roomId: callId
    });
  };

  const setRemoteStream = (event: any) => {
    callSound.stop();
    let RemoteStream: MediaStream;
    console.log('remote stream', event);
    console.log('set remote stream', event.streams[0]);
    RemoteStream = event.streams[0];
    remoteStream.current = RemoteStream;
    setCallAccepted(true);
  };

  const onStartCall = () => {
    callSound.play();
    setareCalling(true);
    isRoomCreator = false;
    socketManager.socket.emit('start_call', callId);
  };

  const onAnswerCall = async () => {
    setareCalling(false);
    ringtoneSound.stop();
    await createOffer(rtcPeerConnection.current);
  };

  const onReCall = () => {
    isRoomCreator = false;
    socketManager.socket.emit('start_call', callId);
  };

  const endCall = () => {
    console.log('end call peer', rtcPeerConnection);
    socketManager.socket.emit('webrtc_user_disconnect', {
      roomId: callId,
      userId: CurrentUser?.userId
    });
    localStream.current?.getTracks().forEach((t) => t.stop());
    remoteStream.current?.getTracks().forEach((t) => t.stop());
    rtcPeerConnection.current.close();
    socketManager.socket.removeAllListeners();
    socketManager.socket.disconnect();
    if (CurrentUser?.userType === 'patient') {
      navigate('/' + callId + '/feedback');
    } else {
      navigate('/endcall');
    }
  };

  const sendIceCandidate = (event: any) => {
    console.log('ice_candidate', event);
    if (event.candidate) {
      socketManager.socket.emit('webrtc_ice_candidate', {
        roomId: callId,
        label: event.candidate.sdpMLineIndex,
        candidate: event.candidate.candidate,
        sdpMid: event.candidate.sdpMid
      });
    }
  };

  // SOCKET EVENT CALLBACKS =====================================================

  let DoctorRoom = (
    <WaitRoomDoctor
      CurrentUser={CurrentUser!}
      GuestUser={GuestUser}
      localStream={localStream.current}
      PatientIsConnected={false}
      LocalCameraError={errorCamera}
      areCalling={areCalling}
      onStartCalling={onStartCall}
      Appointment={CurrentMedicalAppointment?.MedicalAppointment!}
      onEndAppointment={handleEndAppointment}
      onAnswerCall={onAnswerCall}
    />
  );

  let PatientRoom = (
    <WaitRoomPatient
      localStream={localStream.current}
      areCalling={areCalling}
      CurrentUser={CurrentUser!}
      GuestUser={GuestUser}
      LocalCameraError={errorCamera}
      onStartCall={onStartCall}
      Appointment={CurrentMedicalAppointment?.MedicalAppointment!}
      onEndAppointment={handleEndAppointment}
      onAnswerCall={onAnswerCall}
    />
  );

  let WaitingRoomSelector = medicoId ? DoctorRoom : PatientRoom;

  const titleClass = mergeStyles({
    fontSize: 30
  });

  const separatorClass = mergeStyles({
    width: 300
  });

  const NotExistAppointment =
    !CurrentMedicalAppointment ||
    (CurrentMedicalAppointment &&
      !CurrentMedicalAppointment.MedicalAppointment &&
      CurrentMedicalAppointment.loading === true) ? (
      <>
        <div className="spinner">
          <Spinner label="Cargando..." />
        </div>
      </>
    ) : (
      <div className="textCenter">
        <img src={logoCinme} alt="" />
        <Separator className={separatorClass} />
        <Label className={titleClass}>
          El turno no existe o ya se encuentra finalizado
        </Label>
        <Text>Cierre esta ventana y verifique el enlace proporcionado</Text>
      </div>
    );

  return (
    <>
      {!CurrentMedicalAppointment?.MedicalAppointment ? (
        NotExistAppointment
      ) : !callAccepted &&
        remoteStream &&
        localStream &&
        CurrentMedicalAppointment?.MedicalAppointment ? (
        <div>{WaitingRoomSelector}</div>
      ) : (
        <CallRoom
          isMedico={medicoId ? true : false}
          remoteStreamConnected={RemoteStreamConnected}
          OwnerStream={localStream.current}
          GuestStream={remoteStream.current}
          GuestAreConnected={GuestIsConnected}
          GuestUser={GuestUser}
          areCalling={areCalling}
          CurrentUser={CurrentUser!}
          Appointment={CurrentMedicalAppointment?.MedicalAppointment!}
          onReCall={onReCall}
          GuestGone={GuestGone}
          onHangUpCall={endCall}
          onCallAcepted={onAnswerCall}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ...state.MedicalAppointment
});

const mapDispatchToProps = {
  ...MedicalAppointmentStore.actionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoCall as any);
