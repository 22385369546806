import {
  FontIcon,
  IPersonaSharedProps,
  Label,
  mergeStyles,
  Persona,
  PersonaPresence,
  PersonaSize,
  PrimaryButton,
  Separator,
  Text
} from '@fluentui/react';
import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CallControlsComponent } from '../components/CallControls/CallControlsComponent';
import { CallStatusComponent } from '../components/StatusCall/StatusCallCompoment';
import LogoIcthys from '../images/icthysLogo.png';
import LogoCinme from '../images/Logocinme.png';
import { AppState } from '../redux/reducers';
import person from '../images/person.svg';
import { MedicalAppointment } from '../models/MedicalAppointmentModel';
import { User } from '../models/UserModel';

interface IProps {
  OwnerStream: MediaStream | undefined;
  GuestStream: MediaStream | undefined;
  Appointment: MedicalAppointment;
  remoteStreamConnected: boolean;
  isMedico: boolean;
  areCalling: boolean;
  GuestAreConnected: boolean;
  GuestUser: User | undefined;
  CurrentUser: User;
  GuestGone: boolean;
  onHangUpCall: () => void;
  onReCall: () => void;
  onCallAcepted: () => void;
}

const iconClass = mergeStyles({
  fontSize: 50,
  height: 50,
  width: 50,
  color: 'white',
  margin: '0 25px'
});

export const CallRoom = (props: IProps) => {
  const [microphoneState, setMicrophoneState] = useState<boolean>(true);
  const [cameraState, setCameraState] = useState<boolean>(true);

  const Doctor: IPersonaSharedProps = {
    // imageUrl: DoctorIcon,
    imageInitials:
      props.Appointment?.doctor.surName &&
      ', ' &&
      props.Appointment?.doctor.name
        .split(' ')
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join(''),
    text:
      props.Appointment?.doctor.surName &&
      ', ' &&
      props.Appointment?.doctor.name,
    secondaryText: props.Appointment?.doctor.medicalLicense
    // tertiaryText: 'In a meeting',
    // optionalText: 'Available at 4:00pm'
  };

  const Patient: IPersonaSharedProps = {
    // imageUrl: DoctorIcon,
    imageInitials:
      props.Appointment?.patient.surName &&
      ', ' &&
      props.Appointment?.patient.name
        .split(' ')
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join(''),
    text:
      props.Appointment?.patient.surName &&
      ', ' &&
      props.Appointment?.patient.name,
    secondaryText: props.Appointment?.patient.email
    // tertiaryText: 'In a meeting',
    // optionalText: 'Available at 4:00pm'
  };

  const CurrentMedicalAppointment = useSelector(
    (state: AppState) => state.MedicalAppointment
  );

  useEffect(() => {
    console.log('localstream room', props.OwnerStream);
    console.log('RemoteStream room', props.GuestStream);

    if (props.OwnerStream) {
      props.OwnerStream.getAudioTracks()[0].enabled = microphoneState;
      props.OwnerStream.getVideoTracks()[0].enabled = cameraState;
      if (OwnerVideo.current) OwnerVideo.current.srcObject = props.OwnerStream;
    }

    if (props.GuestStream) {
      if (GuestVideo.current) GuestVideo.current.srcObject = props.GuestStream;
    }
  }, [
    props.GuestStream,
    props.OwnerStream,
    props.remoteStreamConnected,
    props.GuestAreConnected
  ]);

  const OwnerVideo = useRef<HTMLVideoElement>(null);
  const GuestVideo = useRef<HTMLVideoElement>(null);

  const handleMicrophoneChangeState = (microphoneCurrentSate: boolean) => {
    if (props.OwnerStream) {
      setMicrophoneState(microphoneCurrentSate);
      props.OwnerStream.getAudioTracks()[0].enabled = microphoneCurrentSate;
    }
  };
  const handleCameraChangeState = (cameraCurrentSate: boolean) => {
    if (props.OwnerStream) {
      setCameraState(cameraCurrentSate);
      props.OwnerStream.getVideoTracks()[0].enabled = cameraCurrentSate;
    }
  };

  let OrganizerVideoElement;
  if (props.OwnerStream) {
    //if (cameraState) {
    OrganizerVideoElement = (
      <video
        className="OwnerVideo"
        playsInline
        muted
        ref={OwnerVideo}
        autoPlay
      />
    );
    //} else {
    //  OrganizerVideoElement = (
    //    <div className="OwnerVideo">
    //      <label className="OnwerInitials">-</label>
    //    </div>
    //  );
    //}
  }

  const UserGone = (
    <div className="userDisconnected">
      <FontIcon aria-label="Running" iconName="Running" className={iconClass} />
      <Label>El usuario finalizo la llamada</Label>
    </div>
  );

  const UserDisconnected = !props.GuestAreConnected ? (
    <div className="userDisconnected">
      <FontIcon
        aria-label="Compass"
        iconName="PlugDisconnected"
        className={iconClass}
      />
      <Label>Se ha perdido la conexión</Label>
      <Text>Esperando que la otra persona se conecte</Text>
    </div>
  ) : (
    <div className="userDisconnected">
      {props.CurrentUser.userType === 'doctor' ? (
        <Persona
          {...Patient}
          size={PersonaSize.size72}
          hidePersonaDetails
          presence={PersonaPresence.online}
          imageAlt="Annie Lindqvist, status is online."
        />
      ) : (
        <Persona
          {...Doctor}
          size={PersonaSize.size72}
          hidePersonaDetails
          presence={PersonaPresence.online}
          imageAlt="Annie Lindqvist, status is online."
        />
      )}

      <Label>
        {props.CurrentUser.userType === 'patient'
          ? 'El Doctor se ha reconectado a la llamada'
          : 'El Paciente se ha reconectado a la llamada'}
      </Label>
      {props.CurrentUser.userType === 'patient' && (
        <Text>Espere a que el Doctor reinicie la llamada</Text>
      )}
      {props.CurrentUser.userType === 'doctor' && (
        <PrimaryButton text="Admitir en la llamada" onClick={props.onReCall} />
      )}
      {props.CurrentUser.userType === 'patient' && props.areCalling && (
        <PrimaryButton
          text="El médico lo esta llamando"
          onClick={props.onCallAcepted}
        />
      )}
    </div>
  );

  let PartnerVideoElement;
  if (props.GuestStream) {
    PartnerVideoElement = props.remoteStreamConnected ? (
      <video className="GuestVideo" playsInline ref={GuestVideo} autoPlay />
    ) : props.GuestGone ? (
      UserGone
    ) : (
      UserDisconnected
    );
  }

  return (
    <div className="CallRoomContainer">
      <CallStatusComponent Status="Vivo" Alive={true} />
      <div className="LogoCinme">
        <img src={LogoCinme} alt="Cinme S.A." />
      </div>
      <div className="CallContainer">
        <div>
          <h1 className="patient">
            {
              <strong>
                {props.isMedico
                  ? CurrentMedicalAppointment?.MedicalAppointment?.patient
                      .name +
                    ' ' +
                    CurrentMedicalAppointment?.MedicalAppointment?.patient
                      .surName
                  : CurrentMedicalAppointment?.MedicalAppointment?.doctor.name +
                    ' ' +
                    CurrentMedicalAppointment?.MedicalAppointment?.doctor
                      .surName}
              </strong>
            }
          </h1>
          <div className="GuestVideoContainer">{PartnerVideoElement}</div>
          <div className="OwnerVideoContainer">{OrganizerVideoElement}</div>
        </div>
      </div>
      <div className="StickyFooter">
        <CallControlsComponent
          cameraInitialState={cameraState}
          microPhoneInitialState={microphoneState}
          onMicrophoneStateChange={handleMicrophoneChangeState}
          onCameraStateChange={handleCameraChangeState}
          onHangUpCall={props.onHangUpCall}
        />
      </div>
      <div className="WaterMarkIcthys">
        <label>Power by</label>
        <img src={LogoIcthys} alt="Ichtys sa." />
      </div>
    </div>
  );
};

export default CallRoom;
