import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { VideoCall } from './pages/VideoCall';
import './App.css';
import { initializeIcons } from '@fluentui/react';
import { EndCallPage } from './pages/EndCall';
import { FeedbackPage } from './pages/Feedback';

initializeIcons();

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/:callId" element={<VideoCall />} />
      <Route path="/:callId/:medicoId" element={<VideoCall />} />
      <Route path="/endcall" element={<EndCallPage />} />
      <Route path="/:appointmentCallId/feedback" element={<FeedbackPage />} />
    </Routes>
  </BrowserRouter>
);

export default App;
