import {
  MedicalAppointmentResponse,
  MedicalAppointmentsResponse
} from './../../models/MedicalAppointmentModel';
import { MedicalAppointment } from '../../models/MedicalAppointmentModel';
import {
  EndMedicalAppoinmentAction,
  EndedMedicalAppoinmentAction,
  RecieveMedicalAppointmentAction,
  RequestMedicalAppointmentAction
} from '../actions/medicalappoinmnetsactions';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Doctor } from '../../models/DoctorModel';
import { Patient } from '../../models/PatientModel';

type KnownAction =
  | RequestMedicalAppointmentAction
  | RecieveMedicalAppointmentAction
  | EndMedicalAppoinmentAction
  | EndedMedicalAppoinmentAction;

export interface MedicalAppointmentState {
  readonly loading: boolean;
  readonly MedicalAppointment: MedicalAppointment | null;
}

export const initialMedialAppointmentState: MedicalAppointmentState = {
  loading: false,
  MedicalAppointment: null
};

export const reducer: Reducer<MedicalAppointmentState> = (
  state: MedicalAppointmentState | undefined,
  incomingAction: Action
): MedicalAppointmentState => {
  if (state === undefined) {
    return initialMedialAppointmentState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'REQUEST_MEDICALAPPOINTMENT': {
      return {
        ...state,
        loading: true
      };
    }
    case 'RECIEVE_MEDICALAPPOINTMENT': {
      return {
        ...state,
        MedicalAppointment: action.MedicalAppoinment,
        loading: false
      };
    }
    case 'END_MEDICALAPPOINMENT': {
      return {
        ...state,
        loading: true
      };
    }
    case 'ENDED_MEDICALAPPOINMENT': {
      return {
        ...state,
        MedicalAppointment: state.MedicalAppointment,
        loading: false
      };
    }
  }

  return state;
};

export const actionCreators = {
  RequestMedicalAppointment:
    (callId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({ type: 'REQUEST_MEDICALAPPOINTMENT', callId: callId });
        dispatch({
          type: 'RECIEVE_MEDICALAPPOINTMENT',
          MedicalAppoinment: {
            patientId: 23,
            doctorId: 33,
            specialtyId: 'Cardiology',
            specialtyDescription: 'Cardiology',
            appointmentDate: new Date(),
            appointmentVerifyCode: '1234',
            appointmentExternalTenant: '22030942-02f0-40a1-a967-3aa30b725d27',
            id: 34,
            status: 1,
            appointmentCallId: callId,
            patient: {
              id: 23,
              name: 'Test',
              surName: 'Patient',
              email: 'patient@test.com',
              externalId: 23,
              nationalIdentityCardType: 3,
              nationalIdentityCardNumber: 11111111,
              medicalRecordNumber: '123456',
              externalTenantId: '22030942-02f0-40a1-a967-3aa30b725d27'
            } as Patient,
            doctor: {
              name: 'Test',
              surName: 'Doctor',
              externalId: '33',
              nationality: 'Argentino',
              nationalIdentityCardType: 3,
              nationalIdentityCardNumber: 22222222,
              medicalLicense: '1234567890',
              externalTenantId: '22030942-02f0-40a1-a967-3aa30b725d27',
              id: 33
            } as Doctor
          } as MedicalAppointment
        });
      }
    },
  EndedMedicalAppointments:
    (appoinmentId: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: 'END_MEDICALAPPOINMENT',
          AppointmentId: appoinmentId
        });
        await fetch(
          'https://consultamedica.idbnar.com/api/dev/turnosmedicos/' +
            appoinmentId +
            '/ended'
        )
          .then(
            (response) => response.json() as Promise<MedicalAppointmentResponse>
          )
          .then((data: MedicalAppointmentResponse) => {
            dispatch({
              type: 'ENDED_MEDICALAPPOINMENT',
              MedicalAppointment: data.data
            });
          });
      }
    }
};
