import {
  FontWeights,
  Label,
  mergeStyles,
  PrimaryButton,
  Rating,
  RatingSize,
  TextField
} from '@fluentui/react';
import { useNavigate } from 'react-router';
import './FeedbackFormComponent.css';

const questionClass = mergeStyles({
  fontSize: 20,
  fontWeight: FontWeights.regular
});

interface IProps {
  AppoinmentCallId: string;
}

export const FeedbackFormComponent = (props: IProps) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="formContainer">
        <div className="question">
          <Label className={questionClass}>Atención</Label>
          <Rating
            max={5}
            className="stars"
            size={RatingSize.Large}
            defaultRating={1}
            ariaLabel="Large stars"
            ariaLabelFormat="{0} of {1} stars"
          />
        </div>
        <div className="question">
          <Label className={questionClass}>Velocidad</Label>
          <Rating
            max={5}
            className="stars"
            size={RatingSize.Large}
            defaultRating={1}
            ariaLabel="Large stars"
            ariaLabelFormat="{0} of {1} stars"
          />
        </div>
        <div className="question">
          <Label className={questionClass}>Calidad profesional</Label>
          <Rating
            max={5}
            className="stars"
            size={RatingSize.Large}
            defaultRating={1}
            ariaLabel="Large stars"
            ariaLabelFormat="{0} of {1} stars"
          />
        </div>
        <TextField
          label="¿Querés dejarnos algún comentario?"
          multiline
          rows={3}
        />
      </div>
      <PrimaryButton text="Enviar" onClick={() => navigate('/endcall')} />
    </>
  );
};
