import React, { useState } from 'react';
import {
  Stack,
  IStackTokens,
  IIconProps,
  Separator,
  IStackProps,
  FontIcon,
  MessageBar,
  MessageBarType
} from '@fluentui/react';
import {
  ActionButton,
  DefaultButton,
  PrimaryButton
} from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';

import './VideoControlComponent.css';
import { User } from '../../models/UserModel';

export interface IProps {
  initialState: boolean;
  PermitStartTheCall: boolean;
  areCalling: boolean;
  GuestUser: User | undefined;
  LocalCameraError: boolean;
  onCameraStateChange: (currentState: boolean) => void;
  onMicrophoneStateChange: (currentState: boolean) => void;
  onStartCall: () => void;
  onAnswerCall: () => void;
  onEndAppointment: () => void;
}

export const VideoControlComponent = (props: IProps) => {
  const stackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 5
  };

  const stackButtonTokens: IStackTokens = {
    maxWidth: '100%'
  };

  const stackProps: IStackProps = { wrap: true };
  const stackButtonProps: IStackProps = {
    horizontalAlign: 'center'
  };
  const [microphoneON, setMicrophoneON] = useState<boolean>(props.initialState);
  const [cameraON, setCameraON] = useState<boolean>(props.initialState);

  const Phone: IIconProps = { iconName: 'Phone' };
  const CancelCall: IIconProps = { iconName: 'DeclineCall' };

  const MicroON: IIconProps = { iconName: 'Microphone' };
  const MicroOFF: IIconProps = { iconName: 'MicOff2' };

  const CameraON: IIconProps = { iconName: 'Video' };
  const CameraOFF: IIconProps = { iconName: 'VideoOff' };

  const HandleMicrophoneClick = (event: any) => {
    const status = !microphoneON;
    setMicrophoneON(status);
    props.onMicrophoneStateChange(status);
  };

  const HandleCameraClick = (event: any) => {
    const status = !cameraON;
    setCameraON(status);
    props.onCameraStateChange(status);
  };

  const ErrorNotGuestConnected = (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={false}
      dismissButtonAriaLabel="Close"
    >
      La otra persona aún no se ha conectado
    </MessageBar>
  );

  return (
    <Stack>
      {!props.LocalCameraError && (
        <Stack horizontal tokens={stackTokens} {...stackProps}>
          <Stack>
            <ActionButton
              onClick={HandleMicrophoneClick}
              iconProps={microphoneON ? MicroON : MicroOFF}
              allowDisabledFocus
            >
              {microphoneON ? 'Silenciar micrófono' : 'Encender micrófono'}
            </ActionButton>
          </Stack>
          <Stack>
            <ActionButton
              onClick={HandleCameraClick}
              iconProps={cameraON ? CameraON : CameraOFF}
              allowDisabledFocus
            >
              {cameraON ? 'Apagar cámara' : 'Encender cámara'}
            </ActionButton>
          </Stack>
        </Stack>
      )}
      {props.PermitStartTheCall && (
        <Stack tokens={stackButtonTokens} {...stackButtonProps}>
          {props.GuestUser ? (
            <PrimaryButton
              text={props.areCalling ? 'Llamando...' : 'Iniciar la llamada'}
              iconProps={Phone}
              onClick={props.onStartCall}
            />
          ) : (
            <>
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
              >
                El paciente no esta conectado
              </MessageBar>
              <DefaultButton
                className="ButtonCall"
                text={'Finalizar Turno sin atención'}
                iconProps={CancelCall}
                onClick={props.onEndAppointment}
              />
            </>
          )}
        </Stack>
      )}
      {!props.PermitStartTheCall && (
        <Stack tokens={stackButtonTokens} {...stackButtonProps}>
          {props.GuestUser ? (
            <>
              <MessageBar
                messageBarType={MessageBarType.info}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
              >
                El médico se ha conectado.
              </MessageBar>
              {props.areCalling && (
                <DefaultButton
                  className="ButtonCall"
                  text={'Atender la llamada'}
                  iconProps={Phone}
                  onClick={props.onAnswerCall}
                />
              )}
            </>
          ) : (
            <>
              <MessageBar
                messageBarType={MessageBarType.info}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
              >
                El medico aún no se ha conectado
              </MessageBar>
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
};
