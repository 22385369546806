import React, { useEffect, useRef, useState } from 'react';
import '../App.css';

import ReactPlayer from 'react-player';

import { Header } from '../commons/pageHeaderComponent';
import { SideBarComponent } from '../commons/sidebarComponent';
import PhoneLogo from '../images/Icon awesome-phone-alt.svg';
import { AppState } from '../redux/reducers';
import { useSelector } from 'react-redux';
import { WaitingVideoComponent } from '../components/WaitingVideo/WaitingVideoComponent';
import { ProcessingPauseIcon } from '@fluentui/react-icons-mdl2';
import { User } from '../models/UserModel';
import { MedicalAppointment } from '../models/MedicalAppointmentModel';

export interface IProps {
  localStream: MediaStream | undefined;
  areCalling: boolean;
  GuestUser: User | undefined;
  CurrentUser: User;
  Appointment: MedicalAppointment;
  LocalCameraError: boolean;
  onStartCall: () => void;
  onAnswerCall: () => void;
  onEndAppointment: () => void;
}

export const WaitRoomPatient = (props: IProps) => {
  const [play, setPlay] = useState(true);

  const CurrentMedicalAppointment = useSelector(
    (state: AppState) => state.MedicalAppointment
  );

  return (
    <div className="App">
      <Header
        UserId={CurrentMedicalAppointment?.MedicalAppointment?.patient!.nationalIdentityCardNumber.toString()}
        UserName={
          CurrentMedicalAppointment?.MedicalAppointment?.patient.name +
          ' ' +
          CurrentMedicalAppointment?.MedicalAppointment?.patient.surName
        }
        Title="Sala de espera virtual"
      />
      <div className="Container">
        {/* <SideBarComponent /> */}
        <div className="PageContainer">
          <h1>Bienvenido a la sala de espera virtual</h1>
          <label className="Title-Green">
            Cuando el médico este disponible, recibirás una llamada
          </label>
          <small>
            Por favor verificá que el volúmen de tu dispositivo este activo
          </small>
          <div className="videoContainer">
            <div className="video">
              <ReactPlayer
                playing={play}
                volume={0.24}
                url="https://www.youtube.com/watch?v=DosngavFKQM"
              />
            </div>
            <WaitingVideoComponent
              stream={props.localStream}
              initialState={true}
              PermitStartTheCall={false}
              onStartCall={props.onStartCall}
              areCalling={props.areCalling}
              CurrentUser={props.CurrentUser}
              GuestUser={props.GuestUser}
              Appointment={props.Appointment}
              LocalCameraError={props.LocalCameraError}
              onEndAppointment={props.onEndAppointment}
              onAnswerCall={props.onAnswerCall}
            />
          </div>

          <div className="Title-Blue">
            <strong>En caso de urgencia no espere!</strong>
            <label>
              <span>
                <img src={PhoneLogo} alt="phone" />
              </span>
              5555-6666
            </label>
            <label>
              <span>
                <img src={PhoneLogo} alt="phone" />
              </span>
              6666-7777
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaitRoomPatient;
