import React, { useState } from 'react';
import MicroControlOn from '../../images/CallControlMicroOn.svg';
import MicroControlOff from '../../images/CallControlMicroOff.svg';
import CameraControlOn from '../../images/CallControlCameraOn.svg';
import CameraControlOff from '../../images/CallControlCameraOff.svg';
import EndCallControl from '../../images/CallControlEndCall.svg';

export interface IProps {
  microPhoneInitialState: boolean;
  cameraInitialState: boolean;
  onCameraStateChange: (currentState: boolean) => void;
  onMicrophoneStateChange: (currentState: boolean) => void;
  onHangUpCall: () => void;
}

export const CallControlsComponent = (props: IProps) => {
  const [microPhoneState, setMicrophoneState] = useState<boolean>(
    props.microPhoneInitialState
  );
  const [cameraState, setCameraState] = useState<boolean>(
    props.cameraInitialState
  );
  const handleChangeMicrophoneStateClick = () => {
    const status = !microPhoneState;
    setMicrophoneState(status);
    props.onMicrophoneStateChange(status);
  };

  const handleChangeCameraStateClick = () => {
    const status = !cameraState;
    setCameraState(status);
    props.onCameraStateChange(status);
  };

  return (
    <div className="CallControls">
      <button
        className="small"
        onClick={handleChangeMicrophoneStateClick.bind(!microPhoneState)}
      >
        <img
          className={!microPhoneState ? 'off' : ''}
          src={microPhoneState ? MicroControlOn : MicroControlOff}
          alt="Microphone"
        />
      </button>
      <button
        className="small"
        onClick={handleChangeCameraStateClick.bind(!cameraState)}
      >
        <img
          className={!cameraState ? 'off' : ''}
          src={cameraState ? CameraControlOn : CameraControlOff}
          alt="Camera"
        />
      </button>
      <button onClick={props.onHangUpCall}>
        <img className="EndCallAction" src={EndCallControl} alt="EndCall" />
      </button>
    </div>
  );
};
