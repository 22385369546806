import * as MedicalAppointments from './medicalappointmentsreducer';
import { MedicalAppointmentState } from './medicalappointmentsreducer';

export interface AppState {
  readonly MedicalAppointment: MedicalAppointmentState | undefined;
}

export const reducers = {
  MedicalAppointment: MedicalAppointments.reducer
};

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => AppState): void;
}
